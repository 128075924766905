import classNames from "classnames";
import { css, styled } from "common/utils/stitches.config";
import * as React from "react";

import { Text } from "..";

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export type ButtonType =
  | "primary"
  | "secondary"
  | "ternary"
  | "danger"
  | "blueWave";

export interface ButtonProps extends IButtonProps {
  children: React.ReactNode;
  buttonType?: ButtonType;
  className?: any;
}

export default function Button(props: ButtonProps) {
  const { buttonType = "primary", children, ...restProps } = props;

  return (
    //@ts-ignore
    <ButtonContainer
      {...restProps}
      buttonType={buttonType}
      className={classNames(
        props.disabled ? styles.disabledButton().toString() : "",
        props.className
      )}
    >
      <ButtonText buttonType={buttonType}>{children}</ButtonText>
    </ButtonContainer>
  );
}

const ButtonText = styled(Text, {
  display: "flex",
  fontSize: "$sm",
  lineHeight: "$xs",
  "@md": {
    fontSize: "$xs",
    lineHeight: "$xs",
  },
  "@lg": {
    fontSize: "$sm",
    lineHeight: "$xs",
  },
  variants: {
    buttonType: {
      primary: {
        color: "$white",
      },
      secondary: {
        color: "white",
      },
      ternary: {
        color: "white",
      },
      danger: {
        color: "$errorDefault",
      },
      blueWave: {
        fontSize: "$xs",
        lineHeight: "$xs",
        "@xl": {
          fontSize: "$sm",
          lineHeight: "$xs",
        },
        color: "white",
        textTransform: "uppercase",
        fontWeight: 500,
        whiteSpace: "nowrap",
      },
    },
  },
});

const ButtonContainer = styled("button", {
  outline: "none",
  userSelect: "none",
  width: "fit-content",
  // minWidth: 180,
  border: "none",
  borderRadius: 999,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  px: 60,
  py: 10,
  "&:disabled": {
    cursor: "not-allowed",
    boxShadow: "none !important",
  },
  variants: {
    buttonType: {
      primary: {
        backgroundColor: "transparent",
        border: "3px solid black",
        transition: "0.2s",
        "& span": {
          color: "black",
        },
        "&:hover": {
          backgroundColor: "black",
          "& span": {
            color: "white",
          },
        },
      },
      secondary: {
        backgroundColor: "$primary",
        "&:hover": {
          backgroundColor: "#437BFF",
          borderColor: "#0062cc",
        },
      },
      ternary: {
        backgroundColor: "$greenButton",
        "&:hover": {
          opacity: "0.9",
        },
      },
      danger: {
        backgroundColor: "transparent",
        border: "1px solid $errorDefault",
        "&:hover": {
          boxShadow: "2px 2px 8px #FB4E4E50",
        },
      },
      blueWave: {
        backgroundColor: "#2F80ED",
        boxShadow: "0px 6px 0px #1D57A5",
        borderRadius: 15,
        py: 12,
        px: 22,
        "@xl": {
          py: 15,
          px: 30,
        },
        "&:hover": {
          opacity: "95%",
        },
        "&:active": {
          transform: "translateY(2px)",
          boxShadow: "0px 4px 0px #1D57A5",
        },
      },
    },
  },
  "@md": {
    py: 12,
  },
});

const styles = {
  disabledButton: css({
    opacity: 0.5,
  }),
};
