import classNames from "classnames";
import { styled } from "common/utils/stitches.config";
import * as React from "react";

import Text from "./text";

export interface BenefitCardProps {
  title: string;
  description: string;
  className?: any;
  icon: React.ReactNode;
}

export default function BenefitCard(props: BenefitCardProps) {
  const { icon, title, description, ...restProps } = props;

  return (
    <Card {...restProps} className={classNames(props.className)}>
      <IconContainer>
        {icon}
        <TitleText>{title}</TitleText>
      </IconContainer>
      <DescriptionText>{description}</DescriptionText>
    </Card>
  );
}

const Card = styled("div", {
  height: "100%",
  borderRadius: 16,
  px: "$sm",
  "@sm": {
    px: 0,
    boxShadow: "none",
  },
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "column",
});

const TitleText = styled(Text, {
  textTransform: "capitalize",
  fontWeight: "bold",
  color: "black",
  fontSize: "$sm",
  lineHeight: "$sm",
  "@sm": {
    mt: 10,
  },
});

const DescriptionText = styled(Text, {
  color: "$lightDescription",
  fontSize: "$sm",
  lineHeight: "$sm",
  mt: "$sm",
  "@sm": {
    whiteSpace: "pre-line",
  },
  "@md": {
    mt: 10,
  },
});
const IconContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 15,
  "@sm": {
    flexDirection: "column",
    alignItems: "start",
    gap: 0,
  },
});
