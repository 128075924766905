import { css } from "common/utils/stitches.config";
import { Button, Tag, Text } from "components/home-element";
import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

import main from "../../../public/assets/img-all-mobil.png";
import phone from "../../../public/assets/m-all-mobil.png";

export default function Vehicle() {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.textContainer()}>
            <Tag>{t("home.vehicle_subheading")}</Tag>
            <Text
              css={{
                fontWeight: "bold",
                color: "black",
                fontSize: 36,
                lineHeight: "$lg",
                mb: "$md",
                "@xl": {
                  fontSize: 48,
                  lineHeight: "$xl",
                },
              }}
            >
              {t("home.vehicle_heading")}
            </Text>
            <Text
              css={{
                color: "$lightDescription",
                fontSize: "$sm",
                lineHeight: "$sm",
                mb: "$xl",
                "@xl": {
                  fontSize: "$md",
                  lineHeight: "$lg",
                },
              }}
            >
              {t("home.vehicle_description")}
            </Text>
            <Link activeClass="active" spy offset={-80} to="contact">
              <Button buttonType="primary">{t("home.download_app")}</Button>
            </Link>
          </div>
          <div className={styles.imageContent()}>
            <div className={styles.imageContainer()}>
              <Image src={main} alt="vehicle" placeholder="blur" />
            </div>
            <div className={styles.phoneContainer()}>
              <Image src={phone} alt="vehicle" placeholder="blur" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  }),
  content: css({
    position: "relative",
    zIndex: 1,
    pt: 72,
    pb: 36,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    px: "$xl",
    margin: "0 auto",
    "@xs": {
      maxWidth: 490,
    },
    "@sm": {
      maxWidth: 690,
    },
    "@md": {
      maxWidth: 740,
      flexDirection: "row-reverse",
      pb: 72,
    },
    "@lg": {
      maxWidth: 900,
      py: "$xxl",
    },
    "@xl": {
      maxWidth: 1140,
      py: "$xxxl",
    },
  }),
  imageContent: css({
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "@md": {
      flexBasis: "50%",
      // flexDirection: "row-reverse",
      // ml: "$lg",
      // pb: "$xxxxl",
    },
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    mt: "$lg",
    mb: "$xxl",
    "@md": {
      flexBasis: "40%",
      ml: "$xl",
      mt: 0,
      mb: 0,
    },
    "@xl": {
      ml: "$xxl",
    },
  }),
  imageContainer: css({
    position: "relative",
    width: "180%",
    "@md": {
      width: "120%",
      left: -50,
    },
    "@xl": {
      maxWidth: 1300,
      left: -90,
    },
  }),
  phoneContainer: css({
    position: "relative",
    width: "100%",
    top: -115,
    "@sm": {
      px: "$lg",
    },
    "@md": {
      position: "absolute",
      width: "85%",
      top: 120,
      right: -50,
    },
    "@lg": {
      width: "75%",
    },
    "@xl": {
      right: -75,
    },
  }),
};
