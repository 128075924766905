import { css } from "common/utils/stitches.config";
import { Button, Text } from "components/home-element";
import Image from "next/legacy/image";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

import main from "../../../public/assets/img-home.png";

export default function Jumbotron() {
  const { t } = useTranslation();

  const handleClick = React.useCallback(() => {}, []);

  return (
    <section id="home">
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.textContainer()}>
            <Text
              css={{
                textAlign: "center",
                fontWeight: "bold",
                color: "black",
                fontSize: 36,
                lineHeight: "$lg",
                mb: "$md",
                px: "$lg",
                "@xl": {
                  fontSize: 48,
                  lineHeight: "$xl",
                },
              }}
            >
              {t("home.jumbotron_heading")}
            </Text>
            <Text
              css={{
                textAlign: "center",
                color: "$darkDescription",
                fontSize: "$sm",
                lineHeight: "$sm",
                mb: 32,
                px: 64,
                "@xl": {
                  fontSize: "$md",
                  lineHeight: "$lg",
                },
              }}
            >
              {t("home.jumbotron_description")}
            </Text>
            <Link to="product" offset={-80} className={styles.buttonLink()}>
              <Button buttonType="primary" onClick={handleClick}>
                {t("home.learn_now")}
              </Button>
            </Link>
          </div>
        </div>
        <div className={styles.imageContent()}>
          <div className={styles.imageContainer()}>
            <Image src={main} alt="jumbotron" placeholder="blur" />
          </div>
        </div>
      </div>
    </section>
  );
}

const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    background: "$primary",
  }),
  content: css({
    overflow: "hidden",
    py: "$xl",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    margin: "0 auto",
    "@xs": {
      maxWidth: 490,
    },
    "@sm": {
      maxWidth: 690,
    },
    "@md": {
      maxWidth: 740,
      px: 32,
      alignItems: "center",
    },
    "@lg": {
      maxWidth: 900,
      pt: "$xxl",
    },
    "@xl": {
      maxWidth: 1140,
      pt: "$xxxxl",
      py: "$xxxl",
    },
  }),
  buttonLink: css({
    display: "none",
    "@md": {
      display: "block",
    },
  }),
  imageContent: css({
    pb: "$xxl",
    margin: "0 auto",
    overflow: "hidden",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexBasis: "100%",
    mt: "$xxl",
    mb: "$xxl",
    "@md": {
      flexBasis: "100%",
      mt: 0,
      mb: "$xl",
    },
  }),
  imageContainer: css({
    position: "relative",
    width: "170%",
    "@md": {
      width: "100%",
    },
    "@xl": {
      maxWidth: 1300,
    },
  }),
};
