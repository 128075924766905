import { css } from "common/utils/stitches.config";
import { Button, Text } from "components/home-element";
import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

import desktop from "../../../public/assets/S4-ilustrasi.png";

export default function Mobile() {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.textContainer()}>
            <div className={styles.subheading()}>
              {t("home.app_subheading")}
            </div>

            <div className={styles.heading()}>
              <Text
                css={{
                  color: "#333333",
                  fontWeight: 700,
                  fontSize: 36,
                  lineHeight: "$lg",
                  mb: "$sm",
                  "@md": {
                    whiteSpace: "pre-line",
                    width: "100%",
                  },
                  "@xl": {
                    fontSize: 48,
                    lineHeight: "$xl",
                    mb: 16,
                  },
                }}
              >
                {t("home.app_heading")}
              </Text>
            </div>
            <Text
              css={{
                color: "#828282",
                fontSize: "$sm",
                fontWeight: 500,
                lineHeight: "$sm",
                mb: "$md",
                "@md": {
                  mb: "$xl",
                },
                "@xl": {
                  fontSize: "20px",
                  lineHeight: "36px",
                },
              }}
            >
              {t("home.app_description")}
            </Text>
            <Link activeClass="active" spy offset={-80} to="contact">
              <Button buttonType="blueWave">{t("home.download_app")}</Button>
            </Link>
          </div>
          <div className={styles.imageContainer()}>
            <Image src={desktop} alt="history" priority />
          </div>
        </div>
      </div>
    </section>
  );
}

const styles = {
  container: css({
    display: "flex",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "white",
    "@md": {
      backgroundColor: "#F4F9FC",
    },
  }),
  content: css({
    position: "relative",
    pt: "$md",
    pb: 64,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    px: "$xl",
    margin: "0 auto",
    "@md": {
      pt: 64,
      flexDirection: "row",
    },
    "@lg": {
      pl: "10%",
    },
  }),
  subheading: css({
    color: "#2F80ED",
    fontWeight: 600,
    textTransform: "uppercase",
    py: 2,
    fontSize: "$sm",
    lineHeight: "$sm",
    "@xl": {
      fontSize: "$md",
      lineHeight: "$lg",
    },
  }),
  heading: css({
    width: "100%",
    position: "relative",
    "@md": {
      width: "130%",
    },
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    mt: "$lg",
    "@md": {
      flexBasis: "40%",
      mt: 0,
    },
  }),
  imageContainer: css({
    mt: "$xl",
    width: "115%",
    "@sm": {
      width: "100%",
    },
    "@md": {
      mt: 0,
      marginLeft: "auto",
      width: "50%",
    },
  }),
  cardImage: css({
    width: "70%",
    mt: "$lg",
    mb: "$xxl",
  }),
  phoneImage: css({
    width: "100%",
    mt: "$lg",
  }),
  image: css({
    background: "red",
    "@lg": {
      width: "500px !important",
      height: "500px !important",
    },
  }),
};
