import { css } from "common/utils/stitches.config";
import { Button, Text } from "components/home-element";
import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";

import waveMobile from "../../../public/assets/S3-blue-wave-mobile.png";
import waveDesktop from "../../../public/assets/S3-blue-wave.png";
import phone from "../../../public/assets/S3-phone-DVA.png";
import sticker from "../../../public/assets/S3-stiker.png";

export default function Sticker() {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles.container()}>
        <div className={styles.content()}>
          <div className={styles.textContainer()}>
            <div className={styles.subheading()}>
              {t("home.sticker_subheading")}
            </div>

            <div className={styles.heading()}>
              <Text
                css={{
                  color: "#333333",
                  fontWeight: 700,
                  fontSize: 36,
                  lineHeight: "$lg",
                  mb: "$sm",
                  "@md": {
                    width: "100%",
                  },
                  "@xl": {
                    fontSize: 48,
                    lineHeight: "$xl",
                    mb: 16,
                  },
                }}
              >
                {t("home.sticker_heading")}
              </Text>
            </div>
            <Text
              css={{
                color: "#828282",
                fontSize: "$sm",
                fontWeight: 500,
                lineHeight: "$sm",
                mb: "$md",
                "@md": {
                  mb: "$xl",
                },
                "@xl": {
                  fontSize: "20px",
                  lineHeight: "36px",
                },
              }}
            >
              {t("home.sticker_description")}
            </Text>
            <a
              href="https://www.tokopedia.com/qrid"
              target="_blank"
              className={styles.link()}
            >
              <Button buttonType="blueWave">
                {t("home.buy_qrid_sticker")}
              </Button>
            </a>
          </div>
          <div className={styles.imageContent()}>
            <div className={styles.waveDesktopContainer()}>
              <Image src={waveDesktop} alt="sticker" priority />
            </div>
            <div className={styles.waveMobileContainer()}>
              <Image src={waveMobile} alt="sticker" priority />
            </div>
            <div className={styles.stickerContainer()}>
              <Image src={sticker} alt="sticker" priority />
            </div>
            <div className={styles.phoneContainer()}>
              <Image src={phone} alt="sticker" priority />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const styles = {
  container: css({
    display: "flex",
    position: "relative",
    overflow: "hidden",
    backgroundColor: "white",
  }),
  content: css({
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    "@lg": {
      pl: "10%",
    },
    margin: "0 auto",
    "@md": {
      flexDirection: "row",
    },
  }),
  subheading: css({
    color: "#2F80ED",
    fontWeight: 600,
    textTransform: "uppercase",
    py: 2,
    fontSize: 14,
    lineHeight: "$sm",
    "@xl": {
      fontSize: "$md",
      lineHeight: "$lg",
    },
  }),
  heading: css({
    width: "100%",
    position: "relative",
    "@md": {
      whiteSpace: "pre-line",
      width: "120%",
    },
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    mt: "$lg",
    px: "$xl",
    "@md": {
      flexBasis: "20%",
      width: "auto",
      mt: 0,
    },
    "@lg": {
      px: 0,
    },
  }),
  imageContent: css({
    position: "relative",
    flexBasis: "65%",
    mt: "35vw",
    width: "100%",
    "@md": {
      mt: 0,
      width: "auto",
    },
    "@xl": {
      ml: "auto",
    },
  }),
  waveDesktopContainer: css({
    display: "none",
    "@md": {
      position: "relative",
      top: 0,
      right: 0,
      width: "100%",
      display: "flex",
    },
  }),
  waveMobileContainer: css({
    position: "relative",
    top: 0,
    right: 0,
    width: "100%",
    "@md": {
      display: "none",
    },
  }),
  stickerContainer: css({
    position: "absolute",
    zIndex: 1,
    top: "-5%",
    left: "27%",
    width: "41%",
    "@md": {
      width: "20%",
      top: "42%",
      left: "-6%",
    },
  }),
  phoneContainer: css({
    position: "absolute",
    zIndex: 2,
    width: "82%",
    left: "9%",
    top: "27%",
    "@md": {
      width: "32%",
      left: "50%",
      top: "15%",
    },
  }),
  cardImage: css({
    width: "70%",
    mt: "$lg",
    mb: "$xxl",
  }),
  phoneImage: css({
    width: "100%",
    mt: "$lg",
  }),
  image: css({
    background: "red",
    "@lg": {
      width: "500px !important",
      height: "500px !important",
    },
  }),
  link: css({
    fontSize: "$xs",
    lineHeight: "$xs",
    "@xl": {
      fontSize: "$sm",
      lineHeight: "$xs",
    },
    color: "white",
    textTransform: "uppercase",
    fontWeight: 500,
    whiteSpace: "nowrap",
    textDecoration: "none",
  }),
};
