import classNames from "classnames";
import { styled } from "common/utils/stitches.config";
import Image from "next/legacy/image";
import * as React from "react";

import Text from "./text";

export interface FeatureCardProps {
  icon: string;
  title: string;
  description: string;
  className?: any;
}

export default function FeatureCard(props: FeatureCardProps) {
  const { icon, title, description, ...restProps } = props;

  return (
    <Card {...restProps} className={classNames(props.className)}>
      <Image src={icon} width={60} height={60} alt={title} layout="fixed" />
      <TitleText>{title}</TitleText>
      <DescriptionText>{description}</DescriptionText>
    </Card>
  );
}

const Card = styled("div", {
  height: "100%",
  borderRadius: 16,
  padding: "1.75rem 2rem 1.75rem 2rem",
  display: "flex",
  flexDirection: "column",
});

const TitleText = styled(Text, {
  textTransform: "uppercase",
  fontWeight: "bold",
  color: "black",
  fontSize: "$sm",
  lineHeight: "$sm",
  mt: "$sm",
  "@md": {
    mt: 20,
  },
});

const DescriptionText = styled(Text, {
  color: "black",
  fontSize: "$sm",
  lineHeight: "$sm",
  mt: "$sm",
  "@md": {
    mt: 20,
  },
});
